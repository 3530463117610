.page-enter {
  opacity: 0.4;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 800ms
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 1;
  transition: opacity 300ms
}

.slidecustom-enter.slideUpTransition {
  transform: translate3d(0, 100%, 0);
}

.slidecustom-enter-active.slideUpTransition {
   transform: translate3d(0, 0, 0);
   transition: all 300ms ease-out;
}

.slidecustom-enter {
   opacity: 0;
}

.slidecustom-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
  
}

.slidecustom-exit {
   position: fixed;
}

.slidecustom-exit-active {
   opacity: 0;
}
