.rs-picker-toggle-value {
    color: #FFF !important;
}

.rs-picker{
    border-radius: 6px;
    width: 250px;
    margin-right: 5px;
    height: 35px;
}

.rs-picker-toggle-textbox{
    background-color: #2d353c;
}

.rs-picker-toggle.rs-btn.rs-btn-subtle {
    background-color: #2d353c;
}

input.rs-picker-toggle-textbox {
    color: white;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

span.rs-picker-toggle-value {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.rs-picker-menu {
    z-index: 9999;
}