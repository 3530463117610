.containerCard {
  display: flex;
  flex: 1;
  flex-direction: row;  
  justify-content: center;
  color: #fff;
  width: 100%;
}

.contentCard{
  width: 100%;
  margin: 0.2rem;  
}

@media screen and (max-width: 1480px) {
  .containerCard {
    flex-wrap: wrap;
  }

  .contentCard {
    width: auto;
    min-width: 16.2rem;
  }
}
@media screen and (max-width: 910px) {
  .contentCard {    
    width: 100%;
  }
}