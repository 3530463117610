
.slide-enter.slideUpTransition {
    transform: translate3d(0, 100%, 0);
}

 .slide-enter-active.slideUpTransition {
     transform: translate3d(0, 0, 0);
     transition: all 800ms ease-out;
 }

 .slide-enter {
     opacity: 0;
 }

 .slide-enter-active {
    opacity: 1;
    transition: opacity 800ms linear;
    
 }
 
 .slide-exit {
     position: fixed;
 }
 
 .slide-exit-active {
     opacity: 0;
 }
  