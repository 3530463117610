.legendImportCaption {
  border: 2px dashed #d5dbe0;
  border-radius: 5px;
  text-align: left;
  width: 30vw;
  height: 30vh;

  .scrollViewlegendImportCaption {
    overflow-y: scroll;
    padding: 1.5rem;
    height: 22vh;
  }

  .legendBold {
    font-weight: bold;
  }

  .legendWarning {
    font-size: 0.85rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  .boxButtonDownload {
    height: 8vh;
    width: 30vw;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .downloadCsv {
      background-color: #F17B1E;
      color: #fff;
      padding: 0.4375rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      font-weight: 600;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: #D8670D;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.legendImportCaptionLower {
  border: 2px dashed #d5dbe0;
  border-radius: 5px;
  text-align: left;

  .scrollViewlegendImportCaption {
    overflow-y: scroll;
    padding: 1.5rem;
  }

  .legendBold {
    font-weight: bold;
  }

  .legendWarning {
    font-size: 0.85rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  .boxButtonDownload {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .downloadCsv {
      background-color: #F17B1E;
      color: #fff;
      padding: 0.4375rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: 0.5rem;
      font-weight: 600;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: #D8670D;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.dragAndDropImportCaption {
  border: 2px dashed #d5dbe0;
  border-radius: 5px;
  width: 40vw;
  height: 30vh;
  text-align: center;
  cursor: pointer;

  .dropZone {
    width: 40vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .dropIcon {
    margin-left: auto;
    margin-right: auto;
  }

  .dropText {
    font-size: 1rem;
    text-align: center;

    .dropTextBold {
      font-weight: bold;
    }

    .dropTextOrange {
      font-weight: bold;
      color: #F17B1E;
    }
  }
}

.dragAndDropImportCaptionLower {
  border: 2px dashed #d5dbe0;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  .dropZone {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .dropIcon {
    margin-left: auto;
    margin-right: auto;
  }

  .dropText {
    font-size: 1rem;
    text-align: center;

    .dropTextBold {
      font-weight: bold;
    }

    .dropTextOrange {
      font-weight: bold;
      color: #F17B1E;
    }
  }
}
