.page-sidebar-minified {
	& .sidebar {
		width: $sidebar-minified-width;
		position: absolute;
		
		& .nav {
			& > li {
				& > a {
					padding: rem(10px) rem(20px);
					
					& span {
						display: none;
					}
					& i {
						margin: 0;
					}
				}
			}
			& li {
				&.has-sub {
					& > .sub-menu {
						display: none !important;
					}
				}
			}
		}
		& .nav-profile {
			display: none;
			
			&.expand {
				display: none !important;
			}
		}
		& .nav-header,
		& .nav-search {
			display: none;
		}
		& .caret {
			position: absolute;
			top: rem(10px);
			
			@if $enable-rtl {
				left: rem(5px);
			} @else {
				right: rem(5px);
			}
			
			&:before {
				@include transform(rotate(0deg) !important);
			}
		}
		& .slimScrollDiv {
			& .slimScrollBar,
			& .slimScrollRail {
				display: none !important;
			}
		}
		&.sidebar-right {
			& .slimScrollDiv,
			& .slimScrollDiv > div {
				overflow: hidden !important;
			}
			& .slimScrollBar {
				display: block !important;
			}
		}
	}
	& .sidebar-bg {
		width: $sidebar-minified-width;
	}
	& .content {
		@if $enable-rtl {
			margin-right: $sidebar-minified-width;
		} @else {
			margin-left: $sidebar-minified-width;
		}
		@include media-breakpoint-down(sm) {
			@if $enable-rtl {
				margin-right: 0;
			} @else {
				margin-left: 0;
			}
		}
	}
	& .footer {
		@if $enable-rtl {
			margin-right: $sidebar-minified-width + $content-padding-x;
		} @else {
			margin-left: $sidebar-minified-width + $content-padding-x;
		}
		@include media-breakpoint-down(sm) {
			@if $enable-rtl {
				margin-right: $content-padding-x-sm;
			} @else {
				margin-left: $content-padding-x-sm;
			}
		}
	}
	& .sidebar-minify-btn {
		& i {
			&:before {
				content: '\f101';
			}
		}
	}
	& .map {
		@include media-breakpoint-up(md) {
			@if $enable-rtl {
				right: $sidebar-minified-width;
			} @else {
				left: $sidebar-minified-width;
			}
		}
	}
	
	&.page-with-right-sidebar {
		@include media-breakpoint-up(md) {
			@if $enable-rtl {
				right: 0;
				left: $sidebar-minified-width;
			} @else {
				right: $sidebar-minified-width;
				left: 0;
			}
		}
		
		& .content {
			@if $enable-rtl {
				margin-right: 0;
				margin-left: $sidebar-minified-width;
			} @else {
				margin-right: $sidebar-minified-width;
				margin-left: 0;
			}
			@include media-breakpoint-down(sm) {
				@if $enable-rtl {
					margin-left: 0;
				} @else {
					margin-right: 0;
				}
			}
		}
		& .footer {
			@if $enable-rtl {
				margin-right: $content-padding-x;
				margin-left: $sidebar-minified-width + $content-padding-x;
			} @else {
				margin-right: $sidebar-minified-width + $content-padding-x;
				margin-left: $content-padding-x;
			}
			@include media-breakpoint-down(sm) {
				@if $enable-rtl {
					margin-right: $content-padding-x-sm;
				} @else {
					margin-right: $content-padding-x-sm;
				}
			}
		}
		& .sidebar {
			& .nav {
				& > li {
					& > a {
						& .icon-img,
						& i {
							float: none;
							width: auto;
							
							@if $enable-rtl {
								margin: 0 rem(5px) 0 0;
							} @else {
								margin: 0 0 0 rem(5px);
							}
						}
						& .caret {
							position: absolute;
							
							@if $enable-rtl {
								left: auto;
								right: rem(3px);
							} @else {
								right: auto;
								left: rem(3px);
							}
							&:before {
								content: '\f053';
							}
						}
					}
					& > .sub-menu {
						@if $enable-rtl {
							padding: rem(10px) 0 rem(10px) rem(30px);
						} @else {
							padding: rem(10px) rem(30px) rem(10px) 0;
						}
					}
				}
			}
		}
		& .sidebar-minify-btn {
			& i {
				&:before {
					content: '\f100';
				}
			}
		}
	}
	&.page-sidebar-fixed {
		& .sidebar {
			position: fixed;
			
			& .slimScrollDiv,
			& .slimScrollDiv > div {
				overflow: hidden !important;
			}
		}
	}
	&:not(.page-sidebar-fixed) {
		& .sidebar {
			& .slimScrollDiv,
			& .slimScrollDiv > div {
				overflow: visible !important;
				height: auto !important;
			}
		}
	}
}